import clsx from 'clsx';
import type { FC } from 'react';
import { ProductSold } from '../../assets/icons/product';
import { PetOwnersBadge, ProductConditionBadge } from '../badges';
import { AuctionEndSoon } from '../badges/AuctionEndSoon';
import { PillBadge } from '../badges/PillBadge';
import { DiscountedPriceLabel } from '../labels/DiscountedPriceLabel';
import { Card } from './Card';
import { CardContent } from './CardContent';
import { CardImageLabel } from './CardImageLabel';
import { CardThumbnail } from './CardThumbnail';
import { CardTitle } from './CardTitle';

export interface AuctionCardPresentationProps {
  className?: string;
  currency: Intl.NumberFormatOptions['currency'];
  title: string;
  productCondition: string;
  isGoodForPets: boolean;
  originalPrice: number;
  discountedPrice: number;
  priceDropTimeInMinutes: number;
  imageSrc: string | null;
  bidCount: number;
  inventoryCount: number;
  itemHasBeenSold: boolean;
  percentageDiscount: number;
  hasDiscountBiggerThan30Percent: boolean;
  auctionWillEndSoon: boolean;
  showBidCount: boolean;
}

export const AuctionCardPresentation: FC<AuctionCardPresentationProps> = ({
  className,
  currency,
  title,
  productCondition,
  isGoodForPets,
  originalPrice,
  discountedPrice,
  priceDropTimeInMinutes,
  imageSrc,
  bidCount,
  inventoryCount,
  itemHasBeenSold,
  percentageDiscount,
  hasDiscountBiggerThan30Percent,
  auctionWillEndSoon,
  showBidCount,
}) => {
  return (
    <Card className={clsx('auction-card h-full', className)}>
      {itemHasBeenSold && (
        <div className="w-full h-full absolute z-20 top-0 left-0">
          <div className="h-1/2 bg-white/80" />
          <CardContent className="h-1/2 bg-white !justify-normal">
            <div className="relative">
              <DiscountedPriceLabel
                discountedPrice={originalPrice === 0 ? undefined : discountedPrice}
                originalPrice={originalPrice || discountedPrice}
                currency={currency}
              />
              <div className="absolute w-full h-full top-0 left-0 bg-white/60" />
            </div>
            <div className="mt-9">Aww, you just missed it!</div>
          </CardContent>
        </div>
      )}
      <CardContent className="!p-0">
        <section className="relative">
          <div className="absolute flex w-full justify-start mt-1 z-10 pr-2">
            {auctionWillEndSoon && (
              <div className="m-2">
                <AuctionEndSoon />
              </div>
            )}
          </div>
          {showBidCount ? (
            <CardImageLabel>
              <span className="text-white">
                {bidCount} bid{bidCount > 1 ? 's' : ''} already
              </span>
            </CardImageLabel>
          ) : null}
          {imageSrc ? (
            <CardThumbnail
              className="aspect-[3/2] bg-white object-contain w-full"
              alt="image-placeholder"
              src={imageSrc}
            />
          ) : (
            <div className="aspect-[3/2] bg-transparent w-full h-full flex justify-center items-center" />
          )}
          {itemHasBeenSold && (
            <div className="w-full h-full absolute top-0 left-0 z-20 flex justify-center items-center ">
              <ProductSold />
            </div>
          )}
        </section>
        <section className="flex flex-col mt-2">
          <CardTitle className="line-clamp-2">{title}</CardTitle>
          <div className="flex items-center gap-2">
            <ProductConditionBadge condition={productCondition} RootContainer={PillBadge} />
            {inventoryCount > 0 && inventoryCount <= 3 && (
              <PillBadge variant="secondary" className="mt-1">
                Only {inventoryCount} left
              </PillBadge>
            )}
          </div>

          <div className="flex flex-col gap-1">
            <DiscountedPriceLabel
              discountedPrice={originalPrice === 0 ? undefined : discountedPrice}
              originalPrice={originalPrice || discountedPrice}
              percentageDiscount={percentageDiscount}
              hasDiscountBiggerThan30Percent={hasDiscountBiggerThan30Percent}
              currency={currency}
              className="mt-1"
            />
            <div className="hidden md:block text-sm">
              Last price drop was <span className="font-bold">{`${priceDropTimeInMinutes} minutes`}</span> ago
            </div>
          </div>
          <div className="flex flex-col gap-2.5">{isGoodForPets ? <PetOwnersBadge /> : null}</div>
        </section>
      </CardContent>
    </Card>
  );
};
