import clsx from 'clsx';
import type React from 'react';

interface PillBadgeProps {
  children: React.ReactNode;
  variant?: 'primary' | 'secondary';
  className?: string;
}

export const PillBadge: React.FC<PillBadgeProps> = ({ children, variant = 'primary', className }) => {
  return (
    <span
      className={clsx(
        '@container-min-w-fit text-nowrap items-center py-[2px] px-[6px] text-2xs font-semibold rounded-full text-black',
        {
          'bg-primary-200': variant === 'primary',
          'bg-secondary-200': variant === 'secondary',
        },
        className,
      )}
      aria-label={`${variant} badge: ${typeof children === 'string' ? children : 'Badge content'}`}
    >
      {children}
    </span>
  );
};
