import clsx from 'clsx';
import type { FC, HTMLAttributes } from 'react';

export type CardTitleProps = HTMLAttributes<HTMLHeadingElement>;

export const CardTitle: FC<CardTitleProps> = ({ className, children, ...props }) => (
  <h3
    className={clsx(
      'card__title text-sm text-gray-600 h-10 font-semibold whitespace-no-wrap overflow-hidden items-start justify-start ',
      className,
    )}
    {...props}
  >
    {children}
  </h3>
);
