import { useStorefrontSettings } from '../../../../app/utils/use-storefront-settings';
import { ClockIcon } from '../icons';

export const AuctionEndSoon = () => {
  const storefrontSettings = useStorefrontSettings();

  return (
    <div className="bg-white rounded-full border border-transparent px-2 py-1 flex items-center shadow-sm">
      <ClockIcon size={16} className="mr-1" color={storefrontSettings?.primary_theme_colors?.[500]} />
      <span className="text-sm font-semibold text-primary-500">Ending Soon</span>
    </div>
  );
};
