import clsx from 'clsx';
import type { FC, PropsWithChildren } from 'react';

export interface CardImageLabelProps {
  className?: string;
}

export const CardImageLabel: FC<PropsWithChildren<CardImageLabelProps>> = ({ className, ...props }) => (
  <div className={clsx('image-status absolute bottom-0 z-10 w-full bg-black/50 px-5 py-0.5 ', className)} {...props} />
);
